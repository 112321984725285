/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import { FiMail } from "react-icons/fi";

import Header from "./header";
import "./layout.css";
import "./../styles/site.scss";

const Layout = ({ showTitle = true, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        {showTitle ? <Header siteTitle={data.site.siteMetadata.title} /> : null}
        <div className="mt-xs-5">{children}</div>
        <footer className="text-muted py-2">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                © {new Date().getFullYear()}, Panda Bridge Ltd. All Rights
                Reserverd
                <br />
                Panda Bridge is not responsible for the content of external
                sites.
              </div>
            </div>
          </div>
        </footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
