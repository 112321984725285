import React, { Component } from "react";
import { Link } from "gatsby";
import { FiMenu, FiChevronRight } from "react-icons/fi";

class Nav extends Component {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    const collapsed = this.state.collapsed;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";

    return (
      <nav className="navbar navbar-expand-lg pb-3">
        <Link className="navbar-brand" to="/">
          <div className="d-flex flex-row align-items-center">
            <h1>Panda Bridge</h1>
            <div />
          </div>
        </Link>
        <button
          onClick={this.toggleNavbar}
          className={`${classTwo} btn`}
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu <FiMenu />
        </button>
        {/* className="collapse navbar-collapse" */}
        <div className={`${classOne}`} id="navbarNavAltMarkup">
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item">
                            <Link className="nav-link" to="/contact">Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">About</Link>
                        </li> */}
            <li className="nav-item">
              <Link
                className="nav-link"
                // activeClassName="active"
                to="/portfolio"
              >
                Clients
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                // activeClassName="active"
                to="/resources"
              >
                Resources
              </Link>
            </li>
            <li className="nav-item d-none d-lg-block">
              <Link
                className="nav-link btn"
                activeClassName="active"
                to="/contact"
              >
                Contact Us
                <FiChevronRight style={{ marginTop: "-0.2em" }} />
              </Link>
            </li>
            <li className="nav-item d-lg-none">
              <Link className="nav-link" activeClassName="active" to="/contact">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Nav;
