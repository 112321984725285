import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

const SubHeader = ({ title, description, link, children }) => (
  <div className="bg-dark py-3 my-3">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="d-block text-center">
            <div>
              <Link to={link} className="highlight">
                <h3 className="m-0">{title}</h3>
              </Link>
            </div>
            {/* <div className="d-none d-sm-block border-right border-dark pr-3 mr-3 h-100">
              &nbsp;
            </div>
            <small className="text-muted">{description}</small> */}
          </div>
        </div>
      </div>
      {children}
    </div>
  </div>
);

SubHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string
};

SubHeader.defaultProps = {
  title: ``,
  description: ``,
  link: `/`
};

export default SubHeader;
