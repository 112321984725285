import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import Layout from "../components/layout";
import { FiChevronRight } from "react-icons/fi";
import { Helmet } from "react-helmet";
import SubHeader from "../components/subheader";

export default ({ data }) => {
  const node = data.allResources.nodes[0];
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href={node.href} />
      </Helmet>
      <SubHeader
        title="Resources"
        description="Curated list of resources to help you and your business grow through technical innovation"
        link="resources"
      />
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12 col-lg-8 offset-lg-2">
            <article>
              <h2>{node.description}</h2>
              <small className="text-muted">
                Added on {node.time}
                <br />
                <a
                  className="highlight"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={node.href}
                >
                  {node.href}
                  <FiChevronRight />
                </a>
              </small>
              <hr />
              <div
                className="articleContainer"
                dangerouslySetInnerHTML={{ __html: node.fields.contentTxt }}
              />
              <hr className="mb-5" />
            </article>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    allResources(filter: { fields: { slug: { eq: $slug } } }) {
      nodes {
        description
        href
        time(formatString: "dddd, MMMM Do YYYY")
        fields {
          tags
          domain
          contentTxt
        }
      }
    }
  }
`;

// export default ResourcePost;
